function $_GET(param) {
    var vars = {};
    window.location.href.replace(location.hash, '').replace(
        /[?&]+([^=&]+)=?([^&]*)?/gi, // regexp
        function (m, key, value) { // callback
            vars[key] = value !== undefined ? value : '';
        }
    );

    if (param) {
        return vars[param] ? vars[param] : false;
    }
    return vars;
}
var load_posts = function () {
    var pageNumber = $('.btn-ajax-video').data("ajax");

    $('.btn-ajax-video').data("ajax", pageNumber + 1);

    var data = {
        'numPosts': 20,
        'pageNumber': pageNumber,      // We pass php values differently!,
        'bh_level': $_GET('bullhornlevel'),
        'bh_product': $_GET('bullhornproduct'),
        'action': 'ajax_loop_videos'
    };
    // We can also pass the url value separately from ajaxurl for front end AJAX implementations

    jQuery.post(ajax_object.ajax_url, data, function (response) {
        //alert('Got this from the server: ' + response);
        $('#videos-container').children('.row').append(response.substring(0, response.length - 1));

    }).done(function () {
        $('.video-title').textfill({
            //'minFontPixels':'6',
            maxFontPixels: 18,
        });
        $(".youtube").YouTubeModal({autoplay: 0, width: 640, height: 370});
    });

};

var load_posts_update = function () {
    var pageNumber = $('.btn-ajax-video').data("ajax");

    var atts = $('.btn-ajax-video').data("atts");

    $('.btn-ajax-video').data("ajax", pageNumber + 1);

    var data = {
        'numPosts': 20,
        'pageNumber': pageNumber,      // We pass php values differently!,
        'atts': atts,
        'bh_updatedate': $_GET('updatedate'),
        'bh_update_bug': $_GET('bullhornproduct'),
        'action': 'ajax_loop_updated'
    };
    // We can also pass the url value separately from ajaxurl for front end AJAX implementations

    jQuery.post(ajax_object.ajax_url, data, function (response) {
        //alert('Got this from the server: ' + response);
        $('#container-updates').append(response.substring(0, response.length - 1));

    }).done(function () {

        $(".new-div-update").each(function (index) {
            var value = $(this).children('.update-content.hidden').html();//document.getElementById('update-content').offsetHeight
            var regex = /\s+/gi;
            var wordCount = value.trim().replace(regex, ' ').split(' ').length;

            //alert(wordCount);

            if (wordCount <= 40) {
                $(this).children('.btn-read-more').hide();

                //$(this).children('.block-with-text').removeClass('block-with-text');

            }


        });
        $(".youtube").YouTubeModal({autoplay: 0, width: 640, height: 370});
    });
    $(".div-update").removeClass('new-div-update');

};
